import React from 'react';
import { Helmet } from 'react-helmet';

import PasswordRecovery from 'components/PassowordRecovery';
import Layout from 'components/layout';

const PasswordRecoveryPage: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Password recovery</title>
      <meta name="og:title" content="Password recovery" />
      <meta name="twitter:title" content="Password recovery" />
    </Helmet>
    <PasswordRecovery />
  </Layout>
);

export default PasswordRecoveryPage;
