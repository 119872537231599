import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useSiteMetadata } from 'helpers/hooks';

import Alert from 'components/UI/Alert';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input/input';
import Label, { LabelText, LabelError } from 'components/UI/Label';

import styles from './PasswordRecovery.module.scss';

interface PasswordRecoveryForm {
  password: string;
  repeatPassword: string;
}

const PasswordRecovery: React.FC = () => {
  const {
    register, handleSubmit, errors, watch,
  } = useForm<PasswordRecoveryForm>();

  const [state, setState] = useState('initial');

  const { apiEndpoint } = useSiteMetadata();

  const recoverPassword = ({ password: newPassword }: {password: string}): void => {
    setState('loading');

    // ?token=<token> -> token=<token> -> ['token', <token>] -> <token>
    const resetToken = window.location.search.slice(1).split('=')[1] || '';

    fetch(`${apiEndpoint}/user/password/confirm-reset`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ resetToken, newPassword }),
    })
      .then((response) => {
        if (response.status === 200) {
          setState('success');
        } else setState('failure');
      }).catch(() => { setState('failure'); });
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>
          <FormattedMessage id="components.passwordRecovery.title" />
        </h1>

        {state === 'failure' && <Alert level="danger"><FormattedMessage id="components.passwordRecovery.failure" /></Alert>}
        {state === 'success' && <Alert level="success"><FormattedMessage id="components.passwordRecovery.success" /></Alert>}

        {(state === 'initial' || state === 'loading') && (
        <form onSubmit={handleSubmit(recoverPassword)}>
          <Label>
            <LabelText>
              <FormattedMessage id="components.passwordRecovery.newPassword" />
            </LabelText>
            <Input
              type="password"
              name="password"
              ref={register({
                required: true,
                minLength: 6,
                maxLength: 190,
                pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
              })}
            />
            <LabelError>
              {errors.password ? (
                <>
                  {errors.password.type === 'required' && <FormattedMessage id="components.passwordRecovery.errors.required" />}
                  {errors.password.type === 'minLength' && <FormattedMessage id="components.passwordRecovery.errors.tooShort" />}
                  {errors.password.type === 'maxLength' && <FormattedMessage id="components.passwordRecovery.errors.tooLong" />}
                  {errors.password.type === 'pattern' && <FormattedMessage id="components.passwordRecovery.errors.invalid" />}
                </>
              ) : null}
            </LabelError>
          </Label>
          <Label>
            <LabelText>
              <FormattedMessage id="components.passwordRecovery.repeatPassword" />
            </LabelText>
            <Input
              type="password"
              name="repeatPassword"
              ref={register({
                required: true,
                validate: (value) => value === watch('password'),
              })}
            />
            <LabelError>
              {errors.repeatPassword ? (
                <>
                  {errors.repeatPassword.type === 'required' && <FormattedMessage id="components.passwordRecovery.errors.repeatRequired" />}
                  {errors.repeatPassword.type === 'validate' && <FormattedMessage id="components.passwordRecovery.errors.dontMatch" />}
                </>
              ) : null}
            </LabelError>
          </Label>
          <div className={styles.btn}>
            <Button type="submit" disabled={state === 'loading'}>
              <FormattedMessage id="components.passwordRecovery.changePassword" />
            </Button>
          </div>
        </form>
        )}
      </div>
    </div>
  );
};

export default PasswordRecovery;
